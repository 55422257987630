<template>
  <v-container class="fill-height" fluid>
    <v-row align="start" justify="center" class="fill-height mx-0">
      <v-col cols="12" class="pa-md-3 pa-0">
        <v-card v-if="$route.meta.fromCarto" class="elevation-0 mt-n12">
          <slot v-if="isAuth && entity"> </slot>
          <v-skeleton-loader
            class="mx-auto"
            ref="skeleton"
            type="table"
            v-else
          ></v-skeleton-loader>
        </v-card>
        <v-card v-else class="elevation-0">
          <v-toolbar flat v-if="!$route.meta.fromEcran" height="48">
            <v-spacer></v-spacer>
            <slot
              name="toolbarBtn"
              v-bind:default="{
                completeName,
                redirectLink,
                redirectLink2
              }"
            ></slot>
          </v-toolbar>
          <slot v-if="isAuth && entity"> </slot>
          <v-skeleton-loader
            class="mx-auto"
            ref="skeleton"
            type="table"
            v-else
          ></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { viewLotMixin } from "../mixins/view_lot";

export default {
  mixins: [viewLotMixin]
};
</script>
