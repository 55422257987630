<template>
  <default-view>
    <v-row
      align="start"
      no-gutters
      justify="center"
      class="fill-height mx-n3 mt-n2"
    >
      <v-col cols="12">
        <onglet-carto> </onglet-carto>
      </v-col>
    </v-row>
  </default-view>
</template>
<script>
import OngletCarto from "../components/carto/PluginGenericobjectLogement.vue";
import DefaultView from "../components/DefaultView";

export default {
  components: { OngletCarto, DefaultView }
};
</script>
