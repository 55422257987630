import { mapGetters, mapMutations, mapActions } from "vuex";
import USER_API from "../api/user";
import { loadStates } from "../utils";
import { PORTAL_CONFIG } from "../config";

export const viewLotMixin = {
  data() {
    return {
      isActivate: false,
      selectedTicket: undefined,
      entity: null,
      completeName: ""
    };
  },
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getEntity",
      "isAuth",
      "getActiveEntity",
      "getUserProfileStatus",
      "getUserData",
      "sessionIsInit",
      "itemLoaded"
    ]),
    redirectLink() {
      //if (this.getActiveEntity.completename) {
      //  clientName = this.getActiveEntity.completename.split(">")[1].trim();
      //}
      return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/request.php?user=${this.getUserToken}&entity=${this.$route.params.id}`;
    },
    redirectLink2() {
      return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/compteur.php?user=${this.$route.params.userToken}&entity=${this.$route.params.id}`;
    },
    profileIsLoaded() {
      return this.getUserProfileStatus === loadStates.SUCCESS;
    }
  },
  methods: {
    ...mapMutations(["setActiveEntity"]),
    ...mapActions(["initSession", "login"]),
    loadActiveEntity() {
      this.getUserProfileStatus &&
        USER_API.setActiveEntity(this.$route.params.id).then(() => {
          this.isActivate = true;
          USER_API.getEntity().then(({ data }) => {
            this.entity = Array.isArray(data)
              ? data.find(entity => entity.id == this.$route.params.id)
              : data;
            this.setActiveEntity(this.entity);
            this.completeName = this.entity.completename.split(">")[1];
          });
        });
    },
    titleText() {
      try {
        const title = this.getActiveEntity.completename.replace(" ", "");
        const parentsEntities = title.split(">");
        let assetTitle = "";
        if (this.$vuetify.breakpoint.smAndDown) {
          assetTitle =
            parentsEntities[2].slice(0, 10) +
            " > " +
            parentsEntities[3].slice(0, 10);
        } else {
          assetTitle = parentsEntities[2] + " > " + parentsEntities[3];
        }
        return assetTitle;
      } catch (error) {
        return "";
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.sessionIsInit && this.getUserProfileStatus)
        if (this.$route.name !== "lots.show" && this.itemLoaded) {
          this.loadActiveEntity();
        } else {
          this.loadActiveEntity();
        }
    });
  },
  watch: {
    getUserProfileStatus() {
      if (this.getUserProfileStatus && this.itemLoaded) {
        this.loadActiveEntity();
      }
    },
    itemLoaded() {
      if (
        this.$route.name !== "lots.show" &&
        this.itemLoaded &&
        this.getUserProfileStatus
      ) {
        this.loadActiveEntity();
      }
    }
  }
};
